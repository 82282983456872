var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-reposicao pa-4"},[_c('v-card-text',[_c('v-row',{staticClass:"mb-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"gray700--text articulat-bold"},[_vm._v(" Atividades com falta / Marcar reposição ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"label":"Pesquisar livro","items":_vm.bookOptions,"item-text":"nome","item-value":"nome","outlined":"","rounded":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.filterBook),callback:function ($$v) {_vm.filterBook=$$v},expression:"filterBook"}})],1),_c('v-col',{staticClass:"text-end"},[_c('ButtonComponent',{attrs:{"propColor":"gray50","propStyle":"color: var(--v-purplemain)","propIconLeftColor":"purplemain","propIconLeft":"visibility","propTitle":"Exibir todas as atividades","propClick":_vm.handleTodasAtividades,"propClass":"articulat-extrabold"}})],1)],1),_c('v-data-table',{staticClass:"table-ticket pa-4 articulat-extrabold",attrs:{"headers":_vm.headersHistoricoReposicaoAluno,"items":_vm.filteredAtividades,"fixed-header":"","footer-props":{
          itemsPerPageOptions: [-1],
        },"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.codigo_referencia",fn:function(ref){
        var item = ref.item;
return [_c('OpenNewTabComponent',{staticClass:"purplemain--text",attrs:{"propObjectLabel":item.codigo_referencia.toString(),"propObjectId":item.turma_id,"propToolName":'Turma'}})]}},{key:"item.presenca",fn:function(ref){
        var item = ref.item;
return [(item.presenca === 'Falta')?_c('v-chip',{staticClass:"articulat-medium red900--text",attrs:{"color":"red400"}},[_vm._v(" "+_vm._s(item.presenca)+" ")]):_vm._e(),(item.presenca === 'Reposição agendada')?_c('v-chip',{staticClass:"articulat-medium purplemain--text",attrs:{"color":"purple300"}},[_vm._v(" Reposição agendada pela escola ")]):_vm._e()]}},{key:"item.status_do_aluno_na_turma_id",fn:function(ref){
        var value = ref.value;
return [_c('v-chip',{staticClass:"articulat-medium",attrs:{"color":"green700","outlined":""}},[_vm._v(" "+_vm._s(_vm._.find( _vm.getStatusDoAlunoOptions(), function (s) { return s.id == value; } ).name)+" ")])]}},{key:"item.atividade_data_executada",fn:function(ref){
        var value = ref.value;
return [_c('v-chip',{staticClass:"purplemain--text articulat-medium",attrs:{"color":"gray50"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ButtonComponent',{staticClass:"mr-3",attrs:{"propColor":"purplemain","propClick":function () { return _vm.marcarReposicao(item); },"propTitle":"Marcar Reposição","propDisabled":item.presenca !== 'Falta'}}),_c('ButtonComponent',{attrs:{"propOutlined":"","propClick":function () { return _vm.handleNaoRepor(item); },"propTitle":"Não irá repor"}})]}}],null,true)})],1)],1),_c('ShowDialog',{ref:"showNaoRepor",attrs:{"propSmall":""}},[_c('NaoRepor',{attrs:{"prop-items":_vm.itemsNaoRepor},on:{"on-confirm":_vm.removerItem,"on-cancel":_vm.onCancel}})],1),_c('ShowDialog',{ref:"showMarcarReposicao",attrs:{"propSmall":"","propWidth":"60%","propCancel":"","propTitle":"Marcar reposição"}},[_c('MarcarReposicao',{attrs:{"propItems":_vm.itemsMarcarReposicao,"prop-opcoes-reposicao":_vm.opcoesReposicao,"propTypeTela":'Reposicao',"propAluno":_vm.propAluno},on:{"on-cancel":_vm.onCancel,"on-confirm":_vm.removerItem}})],1),_c('ShowDialog',{ref:"showTodasAtividades",attrs:{"propTitle":"Todas as atividades","propWidth":"60%","propSmall":"","propCancel":""}},[_c('TodasAtividades',{attrs:{"propItems":_vm.turmas,"propGetFaltas":_vm.getFaltas,"propGetAtividadesByTurmaAluno":_vm.getAtividadesByTurmaAluno,"propPedagogicoHeaders":_vm.pedagogicoHeaders,"propLoading":_vm.loading,"propEvadirAluno":_vm.evadirAluno}})],1),_c('ShowDialog',{ref:"showEvadir",attrs:{"propWidth":"60%","propSmall":""}},[_c('EvadirAluno',{attrs:{"propTurmaAlunoId":_vm.dialogTurmaEvadirAlunoTurmaAlunoId},on:{"on-cancel":_vm.onCancel}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }