import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mainStore, reposicaoStore, turmaAlunoAtividadeStore, turmaStore, salaStore, alunoStore, } from '@/utils/store-accessor';
import { newReposicao } from '@/interfaces/reposicao';
import ButtonComponent from '@/components/ButtonComponent.vue';
import OpcaoNovaData from './opcao/OpcaoNovaData.vue';
import OpcaoButton from './opcao/OpcaoButton.vue';
import OpcaoTurmaOuReposicao from './opcao/OpcaoTurmaOuReposicao.vue';
let MarcarReposicao = class MarcarReposicao extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.turmaSelected = null;
        this.turma = null;
        this.reposicaoSelected = null;
        this.professorSelected = {};
        this.incluirFinanceiro = false;
        this.marcarJustificada = false;
        this.aulaOnlineUrl = '';
        this.opcaoTurma = false;
        this.opcaoOutraReposicao = false;
        this.opcaoNovaData = false;
        this.reposicoesGratis = 4;
        this.reposicoesConcluidas = [];
    }
    getChipStyle(background_color, text_color) {
        const style = {
            color: `var(--v-${text_color})`,
            background: `var(--v-${background_color})`,
            border: `1px solid var(--v-${text_color})`,
        };
        return style;
    }
    getPeriodo(horario) {
        const hour = parseInt(horario.split(':')[0], 10);
        if (hour >= 6 && hour < 12) {
            return 'Matutino';
        }
        else if (hour >= 12 && hour < 18) {
            return 'Vespertino';
        }
        else {
            return 'Noturno';
        }
    }
    handleOpcaoTurma() {
        this.opcaoTurma = true;
        this.opcaoOutraReposicao = false;
        this.opcaoNovaData = false;
        this.reposicaoSelected = null;
        this.professorSelected = {};
    }
    handleOpcaoOutraReposicao() {
        this.opcaoTurma = false;
        this.opcaoOutraReposicao = true;
        this.opcaoNovaData = false;
        this.turmaSelected = null;
        this.professorSelected = {};
    }
    handleOpcaoNovaData() {
        this.opcaoTurma = false;
        this.opcaoOutraReposicao = false;
        this.opcaoNovaData = true;
        this.turmaSelected = null;
        this.reposicaoSelected = null;
    }
    get getCurrentUser() {
        return mainStore.userProfile;
    }
    onCancel() {
        this.$emit('on-cancel');
    }
    async marcarReposicaoOuAulaExtra() {
        this.loading = true;
        if (Array.isArray(this.professorSelected.sala_id) &&
            !this.professorSelected.sala_id_selected) {
            mainStore.addErrorToasted({
                toasted: this.$toasted,
                errors: ['É necessário escolher uma sala para a reposição'],
            });
            this.loading = false;
            return;
        }
        if (this.turmaSelected) {
            // em outra turma
            const obj = {
                id: this.propItems.turma_aluno_atividade_id,
                reposicao_turma_atividade_id: this.turmaSelected.turma_atividade_id,
                reposicao_agendada: true,
                atividade_presenca: null,
                reposicao_concluida: null,
                reposicao_nao_repor: null,
            };
            turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj);
            this.onCancel();
            this.$emit('on-confirm', this.propItems);
            return;
        }
        if (this.reposicaoSelected) {
            // em outra reposicao
            const obj = {
                reposicao_id: this.reposicaoSelected.reposicao_id,
                aluno_id: this.propItems.aluno_id,
                turma_aluno_atividade_id: this.propItems.turma_aluno_atividade_id,
                incluir_financeiro: this.incluirFinanceiro,
                marcar_justificada: this.marcarJustificada,
            };
            reposicaoStore.createReposicoesAlunos([obj]);
            const obj2 = {
                id: this.propItems.turma_aluno_atividade_id,
                reposicao_agendada: true,
                atividade_presenca: null,
                reposicao_concluida: null,
                reposicao_nao_repor: null,
            };
            turmaAlunoAtividadeStore.turmaAlunoAtividadeUpdate(obj2);
            this.onCancel();
            this.$emit('on-confirm', this.propItems);
            return;
        }
        if (Object.keys(this.professorSelected).length > 0) {
            // nova reposicao
            const reposicao = newReposicao();
            reposicao.professor_id = this.professorSelected.professor_id;
            reposicao.company_id = mainStore.userProfile.company.id;
            const data_hora = `${this.professorSelected.dia}T${this.professorSelected.minuto_inicio_formatado}`;
            const horario_brasilia = new Date(data_hora);
            // @ts-ignore
            reposicao.data_hora = horario_brasilia;
            reposicao.sala_id = Array.isArray(this.professorSelected.sala_id)
                ?
                    this.professorSelected.sala_id_selected
                :
                    this.professorSelected.sala_id;
            reposicao.atividade_id = this.propItems.atividade_id;
            if (this.propTypeTela === 'Reposicao') {
                // reposicao.observacao = this.observacao;
                reposicao.status = 0;
                reposicao.valor = 0;
                if (this.getCurrentUser.company.aula_online_allow) {
                    reposicao.aula_online_url = this.aulaOnlineUrl;
                }
                const reposicao_id = await reposicaoStore.createReposicao(reposicao);
                const reposicaoAluno = {
                    aluno_id: this.propItems.aluno_id,
                    reposicao_id,
                    turma_id: this.propItems.turma_id,
                    turma_aluno_id: this.propItems.turma_aluno_id,
                    turma_aluno_atividade_id: this.propItems.turma_aluno_atividade_id,
                    status: 0,
                    valor: null,
                    incluir_financeiro: this.incluirFinanceiro,
                    marcar_justificada: this.marcarJustificada,
                };
                reposicaoStore.createReposicoesAlunos([reposicaoAluno]);
            }
            else if (this.propTypeTela === 'AulaExtra') {
                const reposicaoUpdate = {
                    id: this.propItems.reposicao_id,
                    atividade_id: this.propItems.atividade_id,
                    company_id: mainStore.userProfile.company.id,
                    data_hora,
                    professor_id: reposicao.professor_id,
                    sala_id: reposicao.sala_id,
                    status: 4,
                    tipo: 1,
                    aprovada: false,
                    aula_online_url: this.aulaOnlineUrl
                };
                const reposicao_id = await reposicaoStore.updateReposicao(reposicaoUpdate);
            }
            this.onCancel();
            this.$emit('on-confirm', this.propItems);
            return;
        }
    }
    async mounted() {
        await salaStore.getSalas();
        this.turma = await turmaStore.getTurma(this.propItems.turma_id);
        this.reposicoesConcluidas = await alunoStore.getReposicoesConcluidas({
            aluno_id: this.propAluno.aluno_id,
            turma_id: this.propAluno.turma_id,
        });
    }
};
__decorate([
    Prop({ type: Object, default: () => ({}) })
], MarcarReposicao.prototype, "propItems", void 0);
__decorate([
    Prop({ type: Object, default: () => ({}) })
], MarcarReposicao.prototype, "propAluno", void 0);
__decorate([
    Prop({ type: Object, default: () => ({}) })
], MarcarReposicao.prototype, "propOpcoesReposicao", void 0);
__decorate([
    Prop({ type: String, required: true, default: 'AulaExtra' || 'Reposicao' })
], MarcarReposicao.prototype, "propTypeTela", void 0);
MarcarReposicao = __decorate([
    Component({
        components: {
            ButtonComponent,
            OpcaoTurmaOuReposicao,
            OpcaoButton,
            OpcaoNovaData,
        },
    })
], MarcarReposicao);
export default MarcarReposicao;
