import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import AtividadesComFalta from './AtividadesComFalta.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import OcorrenciaRealizada from '../ocorrencia/OcorrenciaRealizada.vue';
import { dashboardStore, alunoStore } from '@/store';
let AlunaListReposicao = class AlunaListReposicao extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.reposicoesGratis = 4;
        this.reposicoesConcluidas = [];
    }
    async atualizarDados() {
        this.loading = true;
        // @ts-ignore
        await dashboardStore.getDashboardReposicao();
        this.loading = false;
    }
    async atualizarDadosAluno() {
        this.loading = true;
        const alunoAtualizado = { ...this.propSelectedAluno };
        alunoAtualizado.quantidade_faltas = Math.max(0, alunoAtualizado.quantidade_faltas - 1);
        alunoAtualizado.quantidade_faltas_consecutivas = Math.max(0, alunoAtualizado.quantidade_faltas_consecutivas - 1);
        this.$emit('update:propSelectedAluno', alunoAtualizado);
        this.loading = false;
    }
    handlePedagogico() {
        this.$router.push('/main/gestaodeocorrencias/all');
    }
    async mounted() {
        this.reposicoesConcluidas = await alunoStore.getReposicoesConcluidas({
            aluno_id: this.propSelectedAluno.aluno_id,
            turma_id: this.propSelectedAluno.turma_id,
        });
    }
};
__decorate([
    Prop({ default: () => ({}) })
], AlunaListReposicao.prototype, "propSelectedAluno", void 0);
__decorate([
    Prop({ default: () => ({}) })
], AlunaListReposicao.prototype, "propClick", void 0);
AlunaListReposicao = __decorate([
    Component({
        components: {
            AtividadesComFalta,
            ButtonComponent,
            OcorrenciaRealizada,
        },
    })
], AlunaListReposicao);
export default AlunaListReposicao;
