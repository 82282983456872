import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { alunoStore, turmaAlunoAtividadeStore } from '@/store';
import _ from 'lodash';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EvadirAluno from './EvadirAluno.vue';
import NaoRepor from './NaoRepor.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ShowDialog from '@/components/ShowDialog.vue';
import TodasAtividades from './TodasAtividades.vue';
import MarcarReposicao from './MarcarReposicao.vue';
let AtividadesComFalta = class AtividadesComFalta extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.dialogTurmaEvadirAlunoTurmaAlunoId = null;
        this.loading = false;
        this.turmaAlunoAtividade = [];
        this.opcoesReposicao = [];
        this.turmas = [];
        this.filterBook = '';
        this.itemsNaoRepor = {};
        this.itemsMarcarReposicao = {};
        this.combinedAtividades = [];
        this.headersHistoricoReposicaoAluno = [
            { text: 'Turma', value: 'codigo_referencia' },
            { text: 'Livro', value: 'nome' },
            { text: 'Presença', value: 'presenca' },
            { text: 'Status do aluno na turma', value: 'status_do_aluno_na_turma_id' },
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Executada', value: 'atividade_data_executada' },
            { text: 'Ação à ser realizada', value: 'actions' },
        ];
    }
    pedagogicoHeaders(item) {
        const h = [
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Executada', value: 'atividade_data_executada' },
            { text: `${this.getNomeHomework()}`, value: 'atividade_nota' },
        ];
        if (item.nota_preparacao) {
            h.push({ text: 'Nota - Preparação', value: 'atividade_nota_preparacao' });
        }
        if (item.nota_audicao) {
            h.push({ text: 'Nota - Audição', value: 'atividade_nota_audicao' });
        }
        if (item.nota_leitura) {
            h.push({ text: 'Nota - Leitura', value: 'atividade_nota_leitura' });
        }
        if (item.nota_fala) {
            h.push({ text: 'Nota - Fala', value: 'atividade_nota_fala' });
        }
        if (item.nota_escrita) {
            h.push({ text: 'Nota - Escrita', value: 'atividade_nota_escrita' });
        }
        if (item.nota_participacao) {
            h.push({
                text: 'Nota - Participação',
                value: 'atividade_nota_participacao',
            });
        }
        if (item.nota_revisao) {
            h.push({ text: 'Nota - Revisão', value: 'atividade_nota_revisao' });
        }
        h.push({ text: 'Presença', value: 'presenca' });
        return h;
    }
    getFaltas(turma_aluno_id) {
        const atividades = this.getAtividadesByTurmaAluno(turma_aluno_id);
        const contagem = _.countBy(atividades, 'presenca');
        if (contagem.Falta) {
            return contagem.Falta;
        }
        else {
            return 0;
        }
    }
    getAtividadesByTurmaAluno(turmaAlunoId) {
        return _.filter(this.turmaAlunoAtividade, (item) => item.turma_aluno_id === turmaAlunoId);
    }
    combineAtividades() {
        this.combinedAtividades = [];
        this.turmas.forEach((turma) => {
            const atividades = this.getAtividadesByTurmaAluno(turma.turma_aluno_id);
            atividades.forEach((atividade) => {
                atividade.codigo_referencia = turma.codigo_referencia;
                this.combinedAtividades.push(atividade);
            });
        });
    }
    get filteredAtividades() {
        let items = this.combinedAtividades.filter((atividade) => atividade.presenca === 'Reposição agendada' || atividade.presenca === 'Falta');
        if (this.filterBook) {
            items = items.filter((f) => f.nome === this.filterBook);
        }
        return items;
    }
    get bookOptions() {
        return _.uniqBy(this.combinedAtividades, 'nome').map((item) => ({
            nome: item.nome
        }));
    }
    async evadirAluno(turmaAlunoId) {
        this.dialogTurmaEvadirAlunoTurmaAlunoId = turmaAlunoId;
        const form = this.$refs.showEvadir;
        form.show();
    }
    async marcarReposicao(item) {
        this.opcoesReposicao =
            await turmaAlunoAtividadeStore.getTurmaAlunoAtividadeOpcoesReposicao(item.turma_aluno_atividade_id);
        this.itemsMarcarReposicao = item;
        const form = this.$refs.showMarcarReposicao;
        form.show();
    }
    handleNaoRepor(item) {
        this.itemsNaoRepor = item;
        const form = this.$refs.showNaoRepor;
        form.show();
    }
    removerItem(item) {
        this.combinedAtividades = this.combinedAtividades.filter((atividade) => atividade !== item);
        this.$emit('on-confirm');
    }
    handleTodasAtividades() {
        const form = this.$refs.showTodasAtividades;
        form.show();
    }
    async onCancel() {
        this.loading = true;
        const naoRepor = this.$refs.showNaoRepor;
        const evadir = this.$refs.showEvadir;
        const marcarReposicao = this.$refs.showMarcarReposicao;
        naoRepor.close();
        evadir.close();
        marcarReposicao.close();
        setTimeout(async () => {
            this.turmas = await alunoStore.getPedagogicoTurmas(this.id);
            this.turmaAlunoAtividade = await alunoStore.getPedagogico(this.id);
            this.loading = false;
        }, 1000);
        this.$emit('on-cancel');
    }
    async created() {
        this.loading = true;
        this.id = parseInt(this.propAlunoId, 0);
        this.turmas = await alunoStore.getPedagogicoTurmas(this.id);
        this.turmaAlunoAtividade = await alunoStore.getPedagogico(this.id);
        this.combineAtividades();
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number, default: '' })
], AtividadesComFalta.prototype, "propAlunoId", void 0);
__decorate([
    Prop({ type: Object, default: () => ({}) })
], AtividadesComFalta.prototype, "propAluno", void 0);
AtividadesComFalta = __decorate([
    Component({
        components: {
            ButtonComponent,
            EvadirAluno,
            NaoRepor,
            OpenNewTabComponent,
            ShowDialog,
            TodasAtividades,
            MarcarReposicao
        },
    })
], AtividadesComFalta);
export default AtividadesComFalta;
